<template>
  <div class="img-wrapper">
    <img src="@/assets/404.png" alt="" class="img" />
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="less" scoped>
.img-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f6fb;
  padding-top: 40%;
  box-sizing: border-box;
  .img {
    width: 80%;
  }
}
</style>
